<template>
  <div class="navigation">
    <div class="action-wrap">
      <div class="refresh" @click="refresh">
        <img src="../../assets/images/common/refresh.png" alt="" />
        <span>定位</span>
      </div>
      <CustomButton
        v-if="showNavigation"
        size="mini"
        :disabled="!isReady"
        @click="navigation"
      >
        导航
      </CustomButton>
    </div>
    <div class="value">
      <Loading v-if="!isReady" />
      <span v-else>{{ value }}</span>
    </div>
    <div class="map-container" id="mapContainer" style="height: 0;"></div>
  </div>
</template>
<script>
import CustomButton from "@/components/common/button/CustomButton.vue";
import {
  initMap,
  initCurrentLocation,
  // getCurrentAddress,
  mapNavigation,
} from "@/utils/map";
import { Loading } from "vant";
export default {
  components: {
    CustomButton,
    Loading,
  },
  props: {
    showNavigation: {
      type: Boolean,
      default: true,
    },
    // {lng, lat, position}
    navigationItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isReady: false,
      value: "",
      map: "",
      currentLocation: "",
      positions: "",
      marker: "",
    };
  },
  async mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.isReady = false;
      if (!this.map) {
        const { map, currentLocation } = await initMap("mapContainer", {
          currentLocation: true,
        });
        this.map = map;
        this.currentLocation = currentLocation || {};
      } else {
        const currentLocation = await initCurrentLocation(this.map, {});
        this.currentLocation = currentLocation || {};
      }
      // const address = await getCurrentAddress(this.currentLocation);
      this.value = this.currentLocation.address || "";
      this.isReady = true;
    },
    navigation() {
      mapNavigation({
        originLongitude: this.currentLocation.lng,
        originLatitude: this.currentLocation.lat,
        longitude: this.navigationItem.lng,
        latitude: this.navigationItem.lat,
        title: this.navigationItem.position,
        content: this.navigationItem.position,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation {
  width: 100%;
  padding: 12px 24px;
  box-sizing: border-box;
  background-color: #ffffff;
  .action-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .refresh {
      font-size: 14px;
      font-weight: 500;
      color: #91939a;
      img {
        margin-right: 2px;
        width: auto;
        height: 12px;
        transform: translateY(1.5px);
      }
    }
  }
  .value {
    margin-top: 8px;
    font-size: 14px;
    color: #303133;
    text-align: left;
  }
}
</style>
