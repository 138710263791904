<template>
  <div class="big-growers-basic">
    <Form ref="form" :rows="rows" v-model="formData" />
    <Navigation
      ref="navigation"
      class="mb-12"
      :navigation-item="navigationItem"
    />
    <RowLink :links="links" />
    <FooterButton
      v-if="activeName === 'BigGrowersShidanliInfo'"
      :loading="loading"
      @click="submit"
      >保存</FooterButton
    >
    <FooterDoubleButton
      v-else
      cancelLabel="删除"
      confirmLabel="保存"
      :confirmLoading="loading"
      @cancel="handleDelete"
      @confirm="submit"
    />
  </div>
</template>
<script>
import Form from "@/components/common/form/index.vue";
import Navigation from "@/components/navigation/index.vue";
import RowLink from "@/components/select/rowLink.vue";
import FooterButton from "@/components/common/button/FooterButton.vue";
import FooterDoubleButton from "@/components/common/button/FooterDoubleButton.vue";
import { bigGrowersApi } from "@/utils/api.js";
import { Dialog } from "vant";
import { getDealerId, getDealerName } from "@/utils/user.js";
export default {
  components: {
    Form,
    Navigation,
    RowLink,
    FooterButton,
    FooterDoubleButton,
  },
  data() {
    return {
      id: "",
      loading: false,
      isHideTerminalField: true,
      activeName: "",
      formData: {
        kjDealerName: getDealerName(),
      },
    };
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    navigationItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    links() {
      if (this.activeName === "BigGrowersShidanliInfo") {
        return [
          {
            label: "近期拜访记录",
            path: `/visit/list?type=bigGrowers&id=${this.id}&tab=shidanli`,
          },
          {
            label: "账款管理",
            // path: `/bill/manage?type=bigGrowers&id=${this.id}`,
            path: `/bill/bigGrowersManage/1/${this.id}`,
          },
        ];
      } else {
        return [
          {
            label: "近期拜访记录",
            path: `/visit/list?type=bigGrowers&id=${this.id}&tab=ziyou`,
          },
        ];
      }
    },
    rows() {
      return [
        {
          key: "kjDealerName",
          label: "所属经销商",
          required: true,
        },
        {
          key: "bigfarmersType",
          label: "种植大户类型",
          type: "picker",
          required: true,
          dictType: "种植大户类型",
          defaultLabel: "bigfarmersType",
          gap: true,
          handleChange: (value) => {
            // 重置所有信息
            this.isHideTerminalField = true;
            this.$set(this.detail, "kjTerminalNode", "");
            if (value === "终端商种植大户") {
              this.isHideTerminalField = false;
            }
          },
        },
        {
          key: "kjTerminalNode",
          label: "所属终端",
          type: "picker",
          required: true,
          dictType: "terminal",
          defaultLabel: "kjTerminalNodeName",
          hide: this.isHideTerminalField,
        },
        {
          key: "bigfarmersPerson",
          label: "联系人",
          type: "input",
          required: true,
        },
        {
          key: "bigfarmersTel",
          label: "手机",
          type: "input",
          required: true,
          inputType: "tel",
        },
        { key: "birthday", label: "生日", type: "date", required: true },
        {
          key: "sex",
          label: "性别",
          gap: true,
          type: "radio",
          data: [
            { key: "1", value: "男" },
            { key: "0", value: "女" },
          ],
        },
        {
          key: "requirementNum",
          label: "总需求量",
          type: "input",
          required: true,
          inputType: "number",
        },
        {
          key: "orderNum",
          label: "总订货量",
          type: "input",
          required: true,
          inputType: "number",
        },
        {
          key: "plantNum",
          label: "种植亩数",
          type: "input",
          required: true,
          inputType: "number",
        },
        { key: "orderRate", label: "订货率", gap: true }, // 只显示
        {
          key: "plantCover",
          label: "作物覆盖",
          type: "picker",
          required: true,
          dictType: "plantSelect",
          defaultLabel: "plantCover",
        },
        { key: "plantTypeOther", label: "其他", gap: true, type: "input" },
        // { key, label: "使用过的品牌", type: "input" },
        {
          key: "brand",
          label: "使用过的竞品",
          type: "picker",
          dictType: "crm_compete_brand",
          defaultLabel: "brand",
        },
        { key: "brandOther", label: "其他竞品品牌", gap: true, type: "input" },
        {
          key: "kjRegion",
          label: "所属市县",
          type: "picker",
          required: true,
          dictType: "region",
          defaultLabel: "kjRegionName",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjTowninfo[0].fetchTown(
              this.formData.kjRegion
            );
            this.$refs.form.$refs.kjTowninfo[0].reset();
            this.$refs.form.$refs.kjVillage[0].reset();
            this.$set(this.formData, "kjTowninfoName", "");
            this.$set(this.formData, "kjVillageName", "");
          },
        },
        {
          key: "kjTowninfo",
          label: "所属乡镇",
          type: "picker",
          required: true,
          dictType: "town",
          defaultLabel: "kjTowninfoName",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjVillage[0].fetchVillage(
              this.formData.kjTowninfo
            );
            this.$refs.form.$refs.kjVillage[0].reset();
          },
        },
        {
          key: "kjVillage",
          label: "所属村",
          type: "picker",
          dictType: "village",
          defaultLabel: "kjVillageName",
          valueString: "id",
          labelString: "name",
        },
        {
          key: "bussinessManId",
          label: "属地业务员",
          type: "picker",
          gap: true,
          dictType: "salesman",
        },
      ];
    },
  },
  watch: {
    detail() {
      this.formData = {
        ...this.formData,
        ...this.detail,
        birthday: new Date(this.detail.birthday),
      };
      if (this.formData.kjRegion) {
        this.$refs.form.$refs.kjTowninfo[0].fetchTown(this.formData.kjRegion);
      }
      if (this.formData.kjTowninfo) {
        this.$refs.form.$refs.kjVillage[0].fetchVillage(
          this.formData.kjTowninfo
        );
      }
      if (this.formData.bigfarmersType === "终端商种植大户") {
        this.isHideTerminalField = false;
      }
    },
  },
  activated() {
    this.activeName = this.$route.name;
    this.id = this.$route.params.id;
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const {
        bigfarmersType,
        kjTerminalNode,
        bigfarmersTel,
        birthday,
        sex,
        kjRegion,
        kjTowninfo,
        kjVillage,
        plantNum,
        plantCover,
        brand,
        brandOther,
        plantTypeOther,
        requirementNum,
        orderNum,
        bigfarmersPerson,
        bussinessManId,
      } = this.formData;
      try {
        if (this.activeName === "BigGrowersShidanliInfo") {
          await bigGrowersApi.save({
            plantType: "",
            plantCover: plantCover || "",
            kjTerminalNode: kjTerminalNode || "",
            bussinessManId: bussinessManId || "",
            id: this.id,
            bigfarmersType: bigfarmersType || "",
            bigfarmersTel: bigfarmersTel || "",
            birthday: birthday || "",
            sex: sex || "",
            kjRegion: kjRegion || "",
            kjTowninfo: kjTowninfo || "",
            kjVillage: kjVillage || "",
            plantNum: plantNum || "",
            brand: brand || "",
            brandOther: brandOther || "",
            plantTypeOther: plantTypeOther || "",
            requirementNum: requirementNum || "",
            orderNum: orderNum || "",
            bigfarmersPerson: bigfarmersPerson || "",
            kjDealer: getDealerId(),
            bigFarmersPosition: this.$refs.navigation.value,
            bigFarmersCoordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
          });
        } else {
          await bigGrowersApi.distSaveInfo({
            plantType: "",
            plantCover: plantCover || "",
            kjTerminalNode: kjTerminalNode || "",
            bussinessManId: bussinessManId || "",
            id: this.id,
            bigfarmersType: bigfarmersType || "",
            bigfarmersTel: bigfarmersTel || "",
            birthday: birthday || "",
            sex: sex || "",
            kjRegion: kjRegion || "",
            kjTowninfo: kjTowninfo || "",
            kjVillage: kjVillage || "",
            plantNum: plantNum || "",
            brand: brand || "",
            brandOther: brandOther || "",
            plantTypeOther: plantTypeOther || "",
            requirementNum: requirementNum || "",
            orderNum: orderNum || "",
            bigfarmersPerson: bigfarmersPerson || "",
            kjDealer: getDealerId(),
            bigFarmersPosition: this.$refs.navigation.value,
            bigFarmersCoordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
          });
        }
        this.$toast("保存成功");
        // this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
    async handleDelete() {
      try {
        await Dialog.confirm({
          title: "提示",
          message: "确定要删掉这条数据吗?",
        });
        this.confirmDelete();
      } catch (err) {
        //
      }
    },
    async confirmDelete() {
      try {
        await bigGrowersApi.distDelete({ id: this.id });
        this.$toast("删除成功");
        this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
