<template>
  <div class="common-datepicker">
    <div class="content" @click="openDatePciker">
      <div class="label" :class="{ bold: !!dateTime }">
        {{ dateTime || "请选择" }}
      </div>
      <img
        class="arrow-right"
        src="../../assets/images/common/right-arrow.png"
      />
    </div>
    <Popup v-model="isShowDatePicker" round position="bottom">
      <DatetimePicker
        type="datetime"
        :min-date="new Date('1900')"
        :value="value"
        @confirm="onDateConfirm"
        @cancel="closeDatePicker"
      />
    </Popup>
  </div>
</template>

<script>
import { DatetimePicker, Popup } from "vant";
import dayjs from "dayjs";
export default {
  components: {
    DatetimePicker,
    Popup,
  },
  computed: {
    dateTime() {
      return this.value ? dayjs(this.value).format("YYYY/MM/DD HH:mm") : "";
    },
  },
  props: {
    value: {
      type: Date,
      default: () => new Date(),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowDatePicker: false,
    };
  },
  methods: {
    reset() {
      this.$emit("input", null);
    },
    closeDatePicker() {
      this.isShowDatePicker = false;
    },
    openDatePciker() {
      if (this.disabled) {
        return;
      }
      this.isShowDatePicker = true;
    },
    onDateConfirm(date) {
      this.$emit("input", date);
      this.isShowDatePicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.common-datepicker {
  .content {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #2c2c2c;
    .label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #cecece;
      &.bold {
        color: #2c2c2c;
      }
    }
    .arrow-right {
      width: 14px;
      height: 14px;
      margin-left: 4px;
    }
  }
}
</style>
