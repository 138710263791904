<template>
  <div class="common-select-radio">
    <RadioGroup :value="value" class="radio-group">
      <Radio
        v-for="item in list"
        :key="item.key"
        :name="item.key"
        checked-color="#CD001F"
        @click="handleChange(item.key)"
        >{{ item.value }}
      </Radio>
    </RadioGroup>
  </div>
</template>

<script>
import { RadioGroup, Radio } from "vant";
export default {
  components: {
    Radio,
    RadioGroup,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleChange(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.common-select-radio {
  .radio-group {
    display: flex;
    align-items: center;
    ::v-deep .van-radio__icon {
      width: 17px;
      height: 17px;
      margin-left: 13px;
      .van-icon {
        width: 17px;
        height: 17px;
        font-size: 14px;
      }
    }
    ::v-deep .van-radio__label {
      margin-left: 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #cecece;
      flex-shrink: 0;
    }
  }
}
</style>
