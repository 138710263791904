<template>
  <div class="action-wrap" :class="{ fixed }">
    <slot></slot>
    <CustomButton
      :loading="confirmLoading"
      size="large"
      @click="$emit('confirm')"
      >{{ confirmLabel }}</CustomButton
    >
    <CustomButton
      :loading="cancelLoading"
      size="large-plain"
      @click="$emit('cancel')"
      >{{ cancelLabel }}</CustomButton
    >
  </div>
</template>
<script>
import CustomButton from "@/components/common/button/CustomButton.vue";
export default {
  props: {
    confirmLabel: {
      type: String,
      default: "",
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
    cancelLabel: {
      type: String,
      default: "",
    },
    cancelLoading: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CustomButton,
  },
};
</script>
<style lang="scss" scoped>
.action-wrap {
  padding: 0 24px;
  width: calc(100% - 48px);
  & > button {
    margin-top: 12px;
  }
}
.fixed {
  position: fixed;
  bottom: 20px;
  left: 0;
}
</style>
