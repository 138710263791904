<template>
  <HeaderComponent>
    <Tabs :active="value" class="tabs" @click="handleChange">
      <Tab
        v-for="tab in tabs"
        :key="tab.title"
        :title="tab.title"
        :name="tab.title"
      >
      </Tab>
    </Tabs>
    <slot />
  </HeaderComponent>
</template>
<script>
import HeaderComponent from "@/components/header/index.vue";
import { Tabs, Tab } from "vant";
export default {
  components: {
    HeaderComponent,
    Tabs,
    Tab,
  },
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    value: {
      type: String,
      default: '',
    }
  },
  methods: {
    handleChange(tab) {
      this.$emit("click", tab);
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  height: 100%;
  ::v-deep .van-tabs__wrap {
    height: 100%;
    .van-tabs__nav {
      background-color: transparent;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .van-tab {
        font-size: 14px;
        margin: 0 16px;
        color: #ffffff;
        opacity: 0.6;
        &.van-tab--active {
          opacity: 1;
        }
      }
      .van-tabs__line {
        background-color: #ffffff;
        bottom: 10px;
      }
    }
  }
}
</style>
