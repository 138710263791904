<template>
  <Button
    class="custom-btn"
    :class="{
      mini: size === 'mini',
      'mini plain': size === 'mini-plain',
      large: size === 'large',
      full: size === 'full',
      'large plain': size === 'large-plain',
    }"
    :loading="loading"
    :disabled="loading"
    loading-type="spinner"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </Button>
</template>
<script>
import { Button } from "vant";
export default {
  components: {
    Button,
  },
  props: {
    loading: {
      type: Boolean,
    },
    size: {
      type: String,
      default: "mini",
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-btn {
  color: #ffffff;
  background: #cd001f;
  font-size: 12px;
  box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
  border-radius: 5px;
  padding: 0 18px;
  &.mini {
    height: 24px;
  }
  &.large {
    width: 100%;
    height: 44px;
  }
  &.full {
    border-radius: 0;
    width: 100%;
    height: 44px;
    font-size: 15px;
  }
  &.plain {
    border: 1px solid #cd001f;
    background-color: #ffffff;
    color: #cd001f;
  }

  ::v-deep .van-button__text {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
