<template>
  <Form ref="form">
    <Field
      v-for="row in rows"
      :key="row.key"
      :label="row.label"
      :name="row.key"
      :class="{ 'mb-12': row.gap, required: row.required }"
      input-align="right"
      v-show="!row.hide"
    >
      <template #input>
        <!-- 列表选择 -->
        <Picker
          :ref="row.key"
          v-if="row.type === 'picker'"
          :single="!row.multiple"
          :data="row.data || []"
          :dictType="row.dictType || ''"
          :labelString="row.labelString"
          :valueString="row.valueString"
          :defaultLabel="row.defaultLabel ? value[row.defaultLabel] : ''"
          :resetKeys="row.resetKeys"
          :beforeOpenCheck="row.beforeOpenCheck"
          :params="row.params"
          :directReturn="row.directReturn"
          v-model="value[row.key]"
          @reset="reset"
          @on-change="handleChange && handleChange(row.handleChange, $event)"
        />
        <!-- 输入框(可带单位) -->
        <Input
          :ref="row.key"
          v-if="row.type === 'input' || row.type === 'password'"
          :type="row.inputType || row.type"
          :unit="row.unit || ''"
          :disabled="row.disabled"
          v-model="value[row.key]"
          :maxlength="row.maxlength || 200"
        />
        <!-- 区间输入(可带单位) -->
        <RangeInput
          :ref="row.key"
          v-if="row.type === 'range'"
          :unit="row.unit || ''"
          v-model="value[row.key]"
        />
        <!-- 时间选择器 -->
        <DatePicker
          :ref="row.key"
          v-if="row.type === 'date'"
          v-model="value[row.key]"
        />
        <DateRangePicker
          :ref="row.key"
          v-if="row.type === 'dateRange'"
          v-model="value[row.key]"
        />
        <!-- 完整时间选择器 -->
        <DateTimePicker
          :ref="row.key"
          v-if="row.type === 'dateTime'"
          v-model="value[row.key]"
          :disabled="row.disabled"
        />
        <!-- Radio 单选 -->
        <Radio
          :ref="row.key"
          v-if="row.type === 'radio'"
          :list="row.data || []"
          v-model="value[row.key]"
        />
        <!-- Switch -->
        <CustomSwitch
          :ref="row.key"
          v-if="row.type === 'switch'"
          v-model="value[row.key]"
        />
        <!-- inputAndRadio -->
        <InputRadio
          :ref="row.key"
          v-if="row.type === 'inputAndRadio'"
          :list="row.data || []"
          :unit="row.unit || ''"
          v-model="value[row.key]"
        />
        <!-- 城市联动 -->
        <Area
          :ref="row.key"
          v-if="row.type === 'area'"
          v-model="value[row.key]"
        />
        <!-- action(用于一些额外操作) -->
        <div v-if="row.type === 'action'" @click="row.handleAction">
          <div class="placeholder">
            <span v-if="value[row.key]" :class="`value ${row.color || ''}`">
              {{ value[row.key] }}
            </span>
            <span v-else class="label">
              {{ row.placeholder || "请选择" }}
            </span>
            <img
              class="arrow-right"
              src="../../../assets/images/common/right-arrow.png"
            />
          </div>
        </div>
        <!-- 电话 -->
        <a v-if="row.type === 'tel'" :href="`tel:${value[row.key]}`">{{
          value[row.key]
        }}</a>
        <!-- 纯文本 -->
        <span v-if="!row.type" :class="`value ${row.color || ''}`">{{
          row.linkKey
            ? value[row.linkKey]
              ? value[row.linkKey][0][row.field]
              : ""
            : `${value[row.key] || ""}${row.unit || ""}`
        }}</span>
      </template>
    </Field>
    <slot />
  </Form>
</template>
<script>
import { Form, Field } from "vant";
import Area from "@/components/select/area.vue";
import Picker from "@/components/select/picker.vue";
import DatePicker from "@/components/select/datePicker.vue";
import DateTimePicker from "@/components/select/dateTimePicker.vue";
import DateRangePicker from "@/components/common/dateRangePicker";
import Input from "@/components/common/input/index.vue";
import Radio from "@/components/select/radio.vue";
import RangeInput from "../rangeInput";
import CustomSwitch from "@/components/select/switch.vue";
import InputRadio from "@/components/select/inputRadio.vue";
/***************
 * rows配置说明 { key, label, required, type } —— 加配置尽量别影响原有行为并加好注释说明
 * 1. 公共配置
 *    1. key: 唯一索引, 最终表单生成数据为 {key1: value, key2, value}
 *    2. type: 控件类型, {input: 输入框, picker: 弹出列表选择...},具体看代码注释
 *    3. required: 是否显示label的星号(如果为true且没有值的话,在 generateDate() 会弹出错误信息)
 *    4. label: label
 *    5. gap: 当前row是否有"margin-buttom: 12px" - 很常见
 *    6. hide: 当前field是否隐藏
 *    7. onValidation: 字段的验证方法
 * 2. 特殊配置
 *    1. unit: 为type=input时候使用,输入框右边的单位
 *    2. color: 纯文本的时候使用, 文字颜色的设置,目前只有 ['blue']
 *    3. handleAction: type=action 时使用, 用于触发操作的回调函数
 *    4: placeholder: type=action 时使用, 用于触发操作的默认显示, 可以考虑换成v-html形式,目前是string
 *    5. data: type=picker 时使用, 列表选择; 目前是["str1","str2"],可以考虑换为对象数组[{label, value},{label, value}]
 * 3. 数据操作
 *    1. 统一用 v-model
 *    2. validate 方法用来验证
 * *****************/
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    rows: {
      type: Array,
    },
  },
  components: {
    Area,
    Form,
    Field,
    Picker,
    DatePicker,
    DateTimePicker,
    DateRangePicker,
    Input,
    Radio,
    RangeInput,
    CustomSwitch,
    InputRadio,
  },
  methods: {
    // 校验数据
    validate() {
      let errorMessage = "";
      this.rows.some((row) => {
        // 生成数据
        const validateValue = {};
        validateValue[row.key] =
          this.$refs[row.key] && this.$refs[row.key][0]
            ? this.$refs[row.key][0].value
            : "";
        // 字段的自定义验证
        if (row.inputType === "tel") {
          if (/^[1]([3-9])[0-9]{9}$/.test(validateValue[row.key])) {
            //
          } else {
            errorMessage = "手机号格式不正确";
            return true;
          }
        }
        // 验证required
        if (row.type && row.required && !row.hide && !validateValue[row.key]) {
          switch (row.type) {
            case "picker": {
              errorMessage = `请选择${row.label}`;
              break;
            }
            case "input": {
              errorMessage = `请输入${row.label}`;
              break;
            }
            case "date": {
              errorMessage = `请选择${row.label}`;
              break;
            }
          }
          return true;
        } else {
          return false;
        }
      });
      if (errorMessage) {
        this.$toast(errorMessage);
        return false;
      } else {
        return true;
      }
    },
    // 回调函数
    handleChange(callback, value) {
      callback && callback(value);
    },
    // 重置
    reset(key) {
      if (key) {
        this.$nextTick(() => {
          this.$emit("input", { ...this.value, [key]: "" });
        });
      } else {
        this.$nextTick(() => {
          this.$emit("input", {});
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.required {
  ::v-deep .van-cell__title.van-field__label {
    &::before {
      content: "*";
      margin-right: 4px;
      color: #cd001f;
    }
  }
}
::v-deep .van-cell.van-field {
  padding: 10px 24px;
  .van-field__body {
    line-height: 20px;
  }
  .van-field__control {
    min-height: 20px;
  }
  .van-cell__title.van-field__label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #818181;
    width: auto;
  }
  .value {
    font-size: 14px;
    color: #2c2c2c;
    line-height: 20px;
    &.blue {
      color: #006efb;
    }
  }
  .input-wrap {
    width: 100%;
    .van-cell.van-field {
      padding: 0;
      width: 100%;
    }
  }
  .placeholder {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #2c2c2c;
    .label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #cecece;
    }
    .arrow-right {
      width: 14px;
      height: 14px;
      margin-left: 4px;
    }
  }
}
</style>
