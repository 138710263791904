<template>
  <Popup
    class="sort-dialog"
    v-model="visible"
    closeable
    :close-on-popstate="true"
  >
    <div class="title">排序</div>
    <RadioGroup :value="value">
      <div v-for="item in sortData" :key="item.label" class="item">
        <span class="label">{{ item.label }}</span>
        <Radio
          v-for="(name, index) in item.names"
          :key="name"
          :name="name"
          checked-color="#CD001F"
          @click="handleClick(name)"
          >{{ ["升序", "降序"][index] }}</Radio
        >
      </div>
    </RadioGroup>
    <div class="action-wrap">
      <CustomButton size="mini" @click="closeDialog">确定</CustomButton>
    </div>
  </Popup>
</template>
<script>
import { Popup, RadioGroup, Radio } from "vant";
import CustomButton from "@/components/common/button/CustomButton.vue";
export default {
  components: {
    Popup,
    RadioGroup,
    Radio,
    CustomButton,
  },
  props: {
    value: {
      type: String,
      default: () => {
        return {};
      },
    },
    sortData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    handleClick(name) {
      this.$emit("input", name);
    },
    showDialog() {
      this.visible = true;
    },
    closeDialog() {
      this.$emit("confirm");
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.sort-dialog {
  width: 240px;
  padding: 20px;
  text-align: left;
  .title {
    margin-bottom: 20px;
    font-weight: bold;
    color: #000000;
    font-size: 18px;
  }
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 16px;
    .label {
      width: 110px;
      display: inline-block;
    }
    .van-radio-group {
      display: flex;
      .van-radio {
        margin-left: 5px;
      }
    }
  }
  .action-wrap {
    text-align: right;
    margin-top: 40px;
  }
}
</style>
