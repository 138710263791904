<template>
  <div class="bottom-button-wrap">
    <CustomButton size="full" :loading="loading" @click="$emit('click')">
      <slot />
    </CustomButton>
  </div>
</template>
<script>
import CustomButton from "@/components/common/button/CustomButton.vue";
export default {
  components: {
    CustomButton,
  },
  props: {
    loading: {
      type: Boolean,
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-button-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 0;
  margin: 0;
  z-index: 100;
}
</style>
