<template>
  <HeaderComponent class="header">
    <div class="navigate-wrap" @click="navigatePage">
      <span>{{ computedLabel }}</span>
      <img v-if="computedIcon" :src="computedIcon" alt="" />
    </div>
  </HeaderComponent>
</template>
<script>
import HeaderComponent from "@/components/header/index.vue";
import BookIcon from "@/assets/images/tabbar/book.png";
import AddIcon from "@/assets/images/common/add.png";
export default {
  components: {
    HeaderComponent,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      BookIcon,
      AddIcon,
    };
  },
  computed: {
    computedLabel() {
      let label = "";
      switch (this.type) {
        case "book": {
          label = "培训教材";
          break;
        }
        case "add": {
          label = "添加";
          break;
        }
      }
      return this.label || label;
    },
    computedIcon() {
      let icon = "";
      switch (this.type) {
        case "book": {
          icon = this.BookIcon;
          break;
        }
        case "add": {
          icon = this.AddIcon;
          break;
        }
      }
      return this.icon || icon;
    },
    computedPath() {
      let path = "";
      switch (this.type) {
        case "book": {
          path = "/teachingMaterial/list";
          break;
        }
      }
      return this.path || path;
    },
  },
  methods: {
    navigatePage() {
      const path = this.computedPath;
      if (path) {
        if (path === "back") {
          this.$router.back(1);
        } else {
          this.$router.push({ path });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  margin-bottom: 12px;
}
::v-deep .navigate-wrap {
  position: absolute;
  top: 12px;
  right: 24px;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;
  span {
    flex-shrink: 0;
  }
  img {
    margin-left: 4px;
    height: 14px;
    width: auto;
  }
}
</style>
