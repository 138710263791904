<template>
  <div class="image-viewer" v-if="upload || (!upload && images.length > 0)">
    <Uploader
      v-model="files"
      :deletable="upload"
      image-fit="cover" 
      :show-upload="upload"
      :preview-size="74"
      multiple
      :max-count="limit"
      :after-read="handleAfterRead"
      @delete="handleChangeImage"
      :max-size="5 * 1024 * 1024" 
      @oversize="handleOversize"
      capture="camera"
    >
      <div class="upload-btn">+</div>
    </Uploader>
  </div>
</template>

<script>
import { Uploader } from "vant";
import { upload } from '@/utils/request'
import ImageCompressor from 'image-compressor.js'

export default {
  components: {
    Uploader,
  },
  props: {
    images: {
      type: Array,
      default: () => {
        // like ['.../xxx.png', '.../xx.png']
        return [];
      },
    },
    upload: {
      type: Boolean,
      value: true,
    },
    limit: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {
      loading: false,
      tempfiles: [],
    };
  },
  computed: {
    files: {
      get() {
        return !this.upload ? this.formatImage(this.images) : this.tempfiles;
      },
      set(newVal) {
        this.tempfiles = newVal
      },
    },
  },
  watch: {
    files: {
      handler() {
        let ret = []
        this.files.map(item=>{
          if(item.status === 'done') {
            ret.push(item.uploadUrl)
          }
        })
        this.$emit('changeImg', ret)
      },
      deep: true
    }
  },
  activated() {
    this.tempfiles = [];
  },
  methods: {
    handleOversize() {
      this.$toast('请上传小于5M的图片')
    },
    handleAfterRead(file) {
      new ImageCompressor(file.file, {
        quality: 0.2,
        success: (result)=>{
          let image = new window.File([result], `image_${Date.now()}.jpg`, {
            type: result.type,
          });
          this.uploadImage(image, file)
        }
      })
    },
    async uploadImage(image, file) {
      this.loading = true;
      try {
        file.status = 'uploading'
        file.message = '上传中'
        let ret = await upload({file: image})
        file.uploadUrl = ret.data
        file.status = 'done'
        file.message = ''
      } 
      catch(err) {
        this.$toast(err.message)
        file.status = 'failed'
      }
      this.loading = false;
    },
    formatImage(imagesArray) {
      let files = [];
      imagesArray.map &&
        imagesArray.map((item) => {
          files.push({ url: item });
        });
      return files;
    },
    handleChangeImage() {
      // this.$emit('changeImg', this.files)
    },
  },
};
</script>

<style lang="scss" scoped>
.image-viewer {
  width: 100%;
  ::v-deep .van-uploader {
    width: 100%;
    .upload-btn {
      width: 74px;
      height: 74px;
      background: #fafafa;
      font-size: 35px;
      line-height: 74px;
      text-align: center;
      color: #e4e4e4;
    }
    .van-uploader__wrapper {
      display: grid;
      grid-template-columns: 75px 75px 75px 75px;
      grid-template-rows: repeat(auto-fill, 75px);
      justify-content: space-between;
      row-gap: 12px;
      .van-uploader__preview {
        margin: 0;
      }
    }
  }
}
</style>
