<template>
  <van-switch
    :value="value"
    active-color="#CD001F"
    inactive-color="#E4E4E4"
    size="22px"
    @change="handleChange"
  />
</template>
<script>
import { Switch } from "vant";
export default {
  components: { VanSwitch: Switch },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(val) {
      this.$emit("input", val);
    },
  },
};
</script>
